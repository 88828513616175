mat-option {
	.mdc-list-item__primary-text {
		@apply text-inp-txt;

	}
	.mat-pseudo-checkbox {
		&:after {
			@apply text-inp-txt;
		}
	}

	&.mdc-list-item--selected {
		.mdc-list-item__primary-text {
			@apply text-inp-txt #{!important};
			@apply font-semibold #{!important};
		}
		.mat-pseudo-checkbox {
			&:after {
				@apply text-inp-txt #{!important};
				@apply font-semibold #{!important};
			}
		}
	}
}
