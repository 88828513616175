

// exist component - <ib-mat-form-field-wrap> - wrapper for ang mat form field (cuntain custom features)
@mixin buttonColor($color) {
	@apply $color;

	&:hover {
			@apply $color + '-dark';
	}
}

.mdc-text-field--disabled.mdc-text-field--filled
mat-form-field.mat-form-field {
  font-size: 0.74rem;

  input {
    font-size: 1rem;
  }

  label.mat-form-field-label {
    font-size: 1rem;
    top: 1.45em;
  }

  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  &.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.25em) scale(0.75);
    width: 133.33333%;
  }
}
mat-form-field.mat-mdc-form-field {
	display: flex;

	.mat-mdc-text-field-wrapper {
		border: 1px solid transparent;
		@apply rounded-input;
		@apply shadow-ff;
	}

	&.ng-touched.ng-invalid {
		@apply ib-input-error-left-border;
		// .mat-mdc-text-field-wrapper {

		// }
	}

	.mat-mdc-input-element {
		padding-right: 27px;
	}

	.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
		@apply text-inp-placeholder;
	}

	.mdc-text-field.mdc-text-field--disabled  {
		@apply bg-inp-bg-disabled;
	}

	.mdc-text-field--filled:not(.mdc-text-field--disabled) {
		background-color: #ffffff;
	}

	.mat-mdc-form-field-focus-overlay {
    background-color: transparent;
	}

	.mat-mdc-form-field-bottom-align::before {
		display: none;
	}

	.mat-mdc-form-field-error-wrapper {
		position: relative;
	}

	.mat-mdc-form-field-error {
		@apply text-error;
	}

	.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
	.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
		display: none;
	}

	.mdc-line-ripple {
		display: none;
	}

	&.mat-focused {
		.mat-mdc-text-field-wrapper {
			@apply border-secondary;
			@apply shadow-none;

			input.mat-mdc-input-element {
				@apply caret-secondary #{!important};
			}
		}

		&.ng-touched.ng-invalid {

			.mat-mdc-text-field-wrapper {
				@apply shadow-ff-err;
				@apply border-transparent;

				input.mat-mdc-input-element {
					@apply caret-error #{!important};
				}
			}
		}
	}

	.mat-mdc-floating-label {
		color: rgba(33, 37, 41, 0.75);
	}

	.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
		color: rgba(33, 37, 41, 0.75);
	}


	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active{
			-webkit-box-shadow: 0 0 0 30px white inset !important;
	}
}


