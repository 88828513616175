
.mat-mdc-table  {

	// td cell content
	ib-table-cell-content {
		display: block;
		width: 100%;

		&.ib-table-cell-content--number {
			text-align: right;
		}
		&.ib-table-cell-content--money {
			text-align: right;
		}
		&.ib-table-cell-content--date {
			text-align: left;
		}
		&.ib-table-cell-content--percents {
			text-align: right;
		}
		&.ib-table-cell-content--text {
			text-align: left;
		}
	}

	// th cell content

	.mdc-data-table__header-cell {
		.mat-sort-header-container {
			width: 100%;
			position: relative;

			.mat-sort-header-arrow {
				position: absolute;
				right: 0;
				top: 50%;
				margin-top: -6px;
			}
		}

		&.ib-table-cell-content--number {
			.mat-sort-header-container {
				justify-content: center;
			}
		}
		&.ib-table-cell-content--money {
			.mat-sort-header-container {
				justify-content: center;
			}
		}
		&.ib-table-cell-content--date {
			.mat-sort-header-container {
			}
		}
		&.ib-table-cell-content--percents {
			.mat-sort-header-container {
				justify-content: center;
			}
		}
		&.ib-table-cell-content--text {
			.mat-sort-header-container {

			}
		}
	}

  table {
		width: 100%;
		border-collapse: collapse;
		border-radius: 5em;

		/*To remove the last border*/
		tr:last-child td {
			border-bottom:0 solid;
			color: #a5a5a5;
		}
	}
}


// mobile

@screen mdLess {
	.mat-mdc-table {
		border: 0;
		vertical-align: middle;

		thead {
			display: none;
		}

		caption {
			font-size: 1em;
		}

		.mat-mdc-row {
			margin-bottom: 8px;
			@apply shadow-wdgt;
			@apply rounded-wdgt;
			@apply border;
			@apply border-border-d;
			@apply rounded-wdgt;
			display: block;
			height: auto;

			.mat-mdc-cell:last-child {
				@apply rounded-bl-wdgt;
				@apply rounded-br-wdgt;
			}
		}

		.mat-mdc-cell {
			padding-top: 6px;
			padding-bottom: 6px;
			border-bottom: 1px solid ;
			@apply border-border-d;
			display: block;
			font-size: 14px;
			text-align: right;
			height: auto;
		}
		.mat-mdc-cell:before {

			content: attr(data-label);
			float: left;
			text-transform: uppercase;
			font-weight: normal;

			font-size: .85em;
		}
		.mat-mdc-cell:last-child {
			border-bottom: 0;
		}

		.mat-mdc-cell:first-child {
			margin-top: 4%;
		}

	}
}
