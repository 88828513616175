form {
	hr {
		@apply border-t;
		@apply border-f-divider;
		@apply my-24px;
	}

	> ib-mat-form-field-wrap:not(:last-child) {
		@apply mb-inp-form-y-indents;
	}
}
