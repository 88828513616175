ib-spinner {
	display: inline-flex;

	&.ib-spinner--fluid {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		justify-content: center;
		align-items: center;
	}

	&.ib-spinner--inside-btn {
		display: block;
		@apply bg-btn-disab-bg;
		top: 1px;
		bottom: 1px;
		right: 1px;
		left: 1px;@apply rounded-btn;
		z-index: 1;
	}
}
