@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// elements
@import './icons';
@import './form-field';
@import './buttons';
@import './radio';
@import './tables';
@import './dialog';
@import './calendar';
@import './spinner';
@import './tooltip';
@import './datepicker';
@import './input-error';
@import './snackbar';
@import './select';
@import './option';
@import './progress-bar';

// common

// hide all ripples
.mat-ripple-element,
.mdc-icon-button__ripple {
	display: none!important;
}


