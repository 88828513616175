/* You can add global styles to this file, and also import other style files */

// tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// 3rd party
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~intl-tel-input/build/css/intlTelInput.css";

// mixins
@import './styles/utils/mixins';

// fonts
@import './styles/fonts/fonts';

// all ang material
@import './styles/material';


// custome elements
@import './styles/custom';


// ROOT

html {
	width: 100vw;
	height: 100vh;
}

body {
	width: 100%;
	height: 100%;
	font-family: 'Roboto', sans-serif;
	@apply text-txt;
}

app-root {
	display: block;
	width: 100%;
	height: 100%;
}

*[hidden] {
	display: none!important;
}

// --------- inputs

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
