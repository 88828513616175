

button.mdc-button.mat-mdc-button {
	@apply rounded-btn;
	transition: background-color 0.5s;
	border: none;
	padding: 14.5px 30px;
	line-height: initial;

	.mdc-button__label {
		font-weight: 700;
		font-size: 14px;
		color: #fff;
		// text-transform: capitalize; // !!! FORBIDDEN for 'to' 'at' etc
	}

	.mdc-button__ripple {
		display: none;
	}

	.mat-mdc-focus-indicator {
		display: none;
	}

	.mat-mdc-button-touch-target {
		display: none;
	}

	>.material-icons {
		margin-right: 8px;
	}

	&.ib-btn-icon {
		@apply border-none;
		@apply bg-none;
		padding-left: 8px;
		padding-right: 8px;
		min-width: 32px;

		.material-icons {
			margin-right: 0;
		}
	}


	&.ib-btn-outline {
		@apply border;
		@apply border-solid;
		background-color: transparent!important;

		.mat-mdc-button-ripple {
			display: none;
		}

		&:hover {
			.mat-mdc-button-persistent-ripple {
				opacity: 0;
			}
			background-color: transparent!important;
		}
	}

	// primary

	&.ib-btn-primary {
		@apply bg-primary;


		&:hover:not(.ib-btn-outline) {
			@apply bg-opacity-90;
		}

		.material-icons {
			color: #fff;
		}

		&.ib-btn-outline {
			@apply border-primary;
			@apply text-primary;

			.mdc-button__label,
			.material-icons {
				@apply text-primary;
			}
		}
	}

	// secondary

	&.ib-btn-secondary {
		@apply bg-secondary;

		&:hover:not(.ib-btn-outline) {
			@apply bg-opacity-90;
		}

		.material-icons {
			color: #fff;
		}

		&.ib-btn-outline {
			@apply border-secondary;
			@apply text-secondary;

			.mdc-button__label,
			.material-icons {
				@apply text-secondary;
			}
		}
	}

	//  success

	&.ib-btn-success {
		@apply bg-success;

		&:hover:not(.ib-btn-outline) {
			@apply bg-opacity-90;
		}

		&.ib-btn-outline {
			@apply border-success;
			@apply text-success;

			.mdc-button__label {
				@apply text-success;
			}
		}
	}

	//  error

	&.ib-btn-error {
		@apply bg-error;

		&:hover:not(.ib-btn-outline) {
			@apply bg-opacity-90;
		}

		&.ib-btn-outline {
			@apply border-error;
			@apply text-error;

			.mdc-button__label {
				@apply text-error;
			}
		}
	}

	// icon

	&.ib-btn-icon {
		display: flex;
		justify-content: center;
		align-items: center;

		&-primary {
			.material-icons {
				@apply text-primary;
			}
		}

		&-secondary {
			.material-icons {
				@apply text-secondary;
			}
		}
	}

	&:disabled {
		@apply border-btn-disab-bg #{!important};
		@apply bg-btn-disab-bg #{!important};
		@apply text-btn-disab-txt #{!important};

		.material-icons,
		.mdc-button__label {
			@apply text-btn-disab-txt #{!important};
		}
	}

	&.ib-btn-lg {
		height: 46px;
	}

	&.ib-btn-sm {
		height: 30px;

		&.ib-btn-link {
			height: auto;
		}

		.mdc-button__label {
			@apply text-12px;
		}
	}

	&.ib-btn-h-auto {
		height: auto;
	}

	// ??
	&.cdk-focused:not(:active) {
		// @apply outline #{!important};
		// @apply outline-1 #{!important};
		// @apply outline-secondary #{!important};
		outline: 1px solid rgb(0, 0, 251);
	}
}

// link

a.mdc-button.mat-mdc-button,
button.mdc-button.mat-mdc-button {


	&.ib-btn-link {
		position: relative;
		border: none;
		padding: 0;
		height: auto!important;
		min-height: auto;
		min-width: unset!important;

		&:before {
			content: '';
			position: absolute;
			display: block;
			height: 1px;
			bottom: 1px;
			left: 0;
			right: 0;
			background-color: #fff;
		}

		.mdc-button__label {
			white-space: inherit;
			@apply text-primary;
		}

		.mdc-button__ripple {
			&:before {
				display: none;
			}
		}

		.mat-mdc-button-touch-target {
			display: none;
		}

		.mat-ripple {
			display: none!important;
		}

		&.ib-btn-secondary {
			@apply bg-transparent;

			.mdc-button__label {
				white-space: inherit;
				@apply text-secondary;
			}

			&:before {
				@apply bg-secondary;
			}
		}

		&.ib-btn-primary {
			@apply bg-transparent;

			.mdc-button__label {
				white-space: inherit;
				@apply text-primary;
			}

			&:before {
				@apply bg-primary;
			}
		}

		&.ib-btn-white {
			@apply bg-transparent;

			.mdc-button__label {
				@apply text-white;
			}

			&:before {
				@apply bg-white;
			}
		}

		&:hover {
			background-color: transparent !important;
		}

		&:disabled {
			background-color: transparent !important;
			@apply text-btn-disab-txt #{!important};

			.material-icons,
			.mdc-button__label {
				@apply text-btn-disab-txt #{!important};
			}
		}
	}
}

.ib-link {
	position: relative;
	white-space: nowrap;
	letter-spacing: 1.25px;

	&:before {
		content: '';
		position: absolute;
		display: block;
		height: 1px;
		bottom: 1px;
		left: 0;
		right: 0;
		background-color: #fff;
	}


	&.ib-link-primary {
		@apply text-primary;

		&:before {
			@apply bg-primary;
		}
	}
	&.ib-link-secondary {
		@apply text-secondary;

		&:before {
			@apply bg-secondary;
		}
	}
	&.ib-link-white {
		@apply text-white;

		&:before {
			@apply bg-white;
		}
	}
}

button:focus:not(:focus-visible) {
  outline: none!important;
}

