
ib-code-input-wrap {
	display: block;
	position: relative;

	code-input {

		input {
			@apply text-inp-txt #{!important};
			@apply bg-inp-bg #{!important};
			@apply h-input #{!important};
			@apply rounded-input #{!important};
			@apply border-solid #{!important};
			@apply border-b-1 #{!important};
			@apply border-t-0 #{!important};
			@apply border-x-0 #{!important};
			@apply rounded-none #{!important};
			@apply border-b-inp-border #{!important};
			font-size: 20px!important;

			&:disabled {
				@apply text-inp-txt-disabled #{!important};
			}

			&:focus {
				@apply shadow-none #{!important};
				@apply border-b-secondary #{!important};
				@apply border-b-2 #{!important};
			}
		}
	}

	&.ib-code-input-wrap--wrong-code {
		code-input {
			input {
				@apply text-error #{!important};
			}
		}

		form {
			@apply ib-input-error-left-border #{!important};
		}

		.mat-mdc-text-field-wrapper {


			@apply shadow-ff-err #{!important};
			@apply border-transparent #{!important};


			input {

				@apply caret-error #{!important};
			}
		}
	}
}
