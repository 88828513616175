.mat-mdc-radio-button  {
	.mat-ripple {
		display: none!important;
	}

	.mdc-radio__background {
		&:before {
			display: none;
		}
	}

	.mdc-form-field {
		label {
			text-transform: capitalize;
			cursor: pointer;
		}
	}
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
	@apply border-primary #{!important};
}
