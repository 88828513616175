.mat-mdc-dialog-container {

	.mat-mdc-dialog-title {
		@apply border-b;
		@apply border-border-d;
	}

	.mdc-dialog__container .mat-mdc-dialog-content {
		padding-top: 16px;
	}

	.mat-mdc-dialog-actions {
		@apply border-t;
		@apply border-border-d;
		padding: 16px 24px;
	}
}

.ib-modal-img-view {
	max-width: 100vw!important;

	.mat-mdc-dialog-content  {
		max-height: 100vh!important;
	}

	.mat-mdc-dialog-surface {
		background-color: rgba(#000, 0.5)!important;
	}

	.mat-mdc-dialog-content {
		display: flex!important;
		background-color: transparent!important;
	}

}

