ib-mat-form-field-wrap {
	display: block;
	position: relative;

	ib-tooltip {
		display: block;
		@apply mt-inp-hint-mt;
		@apply ml-input-helpers-ml;

		&.ib-tooltip-by-hover {
			margin-top: 0;
			position: absolute;
			top: 14px;
			right: -20px;
		}
	}

	.ib-mat-form-field-wrap-passw-toggle {
		position: absolute;
		top: 11px;
		right: 8px;
	}

	.ib-mat-form-field-wrap-edit-btn {
		position: absolute;
		top: 18px;
		right: 18px;
	}

	ib-input-error {
		@apply ml-input-helpers-ml;
		line-height: 1.3;
	}

	ib-input-clear-btn {
		position: absolute;
		top: 20px;
		right: 16px;
	}

	.ib-mat-form-field-wrap-select-chevron {
		position: absolute;
		top: 20px;
		right: 16px;
	}

	.mat-mdc-select-arrow-wrapper {
		display: none!important;
	}

	ib-spinner {
		position: absolute;
		top: 20px;
		right: 16px;
	}

	&:hover {
		ib-input-clear-btn {
			display: flex;
		}
	}

	// right alignment (numbers)

	&.ib-mat-form-field-wrap--right-align {
		.mat-mdc-input-element {
			text-align: right;
			padding-right: 0;
			padding-left: 27px;
		}

		&.ib-mat-form-field-wrap--with-passw-toggle {
			.ib-mat-form-field-wrap-passw-toggle {
				position: absolute;
				top: 11px;
				left: 8px;
				right: auto;
			}
		}
		&.ib-mat-form-field-wrap--with-edit-btn {
			.ib-mat-form-field-wrap-edit-btn {
				position: absolute;
				top: 18px;
				right: auto;
				left: 18px;
			}
		}
		&.ib-mat-form-field-wrap--with-clear-btn {
			ib-input-clear-btn {
				position: absolute;
				top: 20px;
				left: 16px;
				right: auto;
			}
		}

		// &.ib-mat-form-field-wrap--with-value {

		// }
	}
}

app-root.ib-touchscreen {
	ib-mat-form-field-wrap ib-input-clear-btn {
		display: flex;
	}
}
