
ib-currency {
	display: block;
	position: relative;

	.mat-mdc-form-field.mat-focused {
		@apply border-secondary;
	}
	&.ib-currency--touched.ib-currency--invalid {
		@apply ib-input-error-left-border;


		.mat-mdc-form-field {

			.mat-mdc-text-field-wrapper {
				@apply border-transparent;
			}
			&.mat-focused {
				@apply border-transparent;
				@apply rounded-tl-input;
				@apply rounded-bl-input;
				@apply shadow-ff-err;
			}
		}
	}
}
