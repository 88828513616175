
mat-mdc-snack-bar-container,
mat-snack-bar-container {
	z-index: 2000;
	.mdc-snackbar__surface {
		z-index: 2000;
		background-color: transparent!important;
		border-radius: 0!important;
		padding: 0!important;

		.mat-mdc-snack-bar-label {
			padding: 0!important;
		}
	}

	.ib-snackbar {
		display: block;
		padding: 8px 16px;
		@apply shadow-wdgt;
		border-radius: 4px;
		background-color: #fff;

		.ib-snackbar__msg {
			@apply text-txt;
			padding: 2px 16px;
		}
		.ib-snackbar__icon {
			color: #fff;
		}

		.ib-snackbar__close {
			.material-icons {
				@apply text-secondary;
			}
		}

		&--error {
			.material-icons {
				@apply text-error;
			}
		}
		&--success {
			.material-icons {
				@apply text-success;
			}
		}
		&--warning {
			.material-icons {
				@apply text-warning;
			}
		}
		&--info {
			.material-icons {
				@apply text-secondary;
			}
		}
	}
}

